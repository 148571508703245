<template>

  <div :class="[isTransparentHeader ? 'main-menu transparentHeader' : 'main-menu', overlayShow ? 'sub-menu-active' : '', isSticky ? 'is-sticky' : '', isNewsletterStickyOpen ? 'newsletter-sticky-open' : '']"
       ref="mainMenu" v-show="showHeader">
    <!-- Overlay -->
    <transition name="main-menu-fade">
      <div v-show="overlayShow"
           @click="closeAll()"
           class="main-menu-overlay" :class="{'search': searchShow}"/>
    </transition>
    <div class="main-menu__container">
      <!-- Bar -->
      <div class="main-menu-bar"
           itemscope
           itemtype="http://schema.org/SiteNavigationElement">
        <div class="main-menu-bar__left">
          <language-switcher v-if="langSwitcherIsEnable && !smallScreen" :current-language="currentLanguage" :item-data="languageSwitcherData"> </language-switcher>
          <button class="main-menu-search__trigger"
                  :class="{ 'is-active': searchShow }"
                  :aria-expanded="searchShow ? 'true' : 'false'"
                  @click="searchToggle()"
                  aria-label="search">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7 11.1723C4.7 7.54247 7.64257 4.5999 11.2724 4.5999C14.9023 4.5999 17.8448 7.54247 17.8448 11.1723C17.8448 14.8022 14.9023 17.7447 11.2724 17.7447C7.64257 17.7447 4.7 14.8022 4.7 11.1723ZM11.2724 3.3999C6.97983 3.3999 3.5 6.87973 3.5 11.1723C3.5 15.4649 6.97983 18.9447 11.2724 18.9447C13.2003 18.9447 14.9642 18.2428 16.3226 17.0806L19.6764 20.4246L20.5237 19.5748L17.1719 16.2328C18.3394 14.873 19.0448 13.1051 19.0448 11.1723C19.0448 6.87973 15.565 3.3999 11.2724 3.3999Z" />
            </svg>
          </button>
          <accessibility-modal-window
            :title="accTitle"
            :icon-src="accBlackIconSrc"
            :icon-alt="accIconAlt"
            :animation-label="accAnimationLabel"
            :contrast-label="accContrastLabel"
            :enable-animation="accEnableAnimation"
            :enable-contrast="accEnableContrast"
            :enable-toggle-text="accEnableToggleText"
            :disable-toggle-text="accDisableToggleText"
            :event-label="accEventLabel"
            :page-category="pageCategory" v-if="accEnable">
          </accessibility-modal-window>
          <a @click="openAccessibility()" class="accessibility-btn" v-if="!smallScreen && accEnable">
                  <span class="main-menu-nav__icon">
                    <img loading="lazy" :src="accBlackIconSrc" :alt="accIconAlt || accTitle.toLowerCase()">
                  </span>
            <span class="main-menu-nav__icon transparent-icon">
                    <img loading="lazy" :src="accWhiteIconSrc" :alt="accIconAlt || accTitle.toLowerCase()">
                  </span>
            <span class="accessibility-menu">{{accTitle}}</span>
          </a>
        </div>
        <slot name="mainMenuBarLogo" />
        <button @click="menuToggle"
                class="main-menu-nav__trigger"
                :class="{ 'is-active': menuShow }"
                :aria-expanded="menuShow ? 'true' : 'false'"
                v-tag:useractionevent="{ label: 'picto', category: 'main menu navigation', action: 'select::burger menu', event_name: 'menu_click', click_area: 'burger menu', breadcrumb: 'picto' }">
          <span class="top" />
          <span class="center1" />
          <span class="center2" />
          <span class="bottom" />
          <span class="is-sr-only">{{ a11yAriaLabelMainMenu }}</span>
        </button>
        <!-- Cart Items-->
        <div id="productCart__handle" class="productCart__handle" @click="showCart()">
          <div class="icon productCart__bag"><img src="/frontend/master/images/icons/bag--black.svg" alt="bagBlack"></div>
          <span id="productCartCount" class="productCart__handle--count">0</span>
        </div>
        <!-- account nav -->
        <slot name="accountNav"/>


      </div>

      <!-- Search -->
      <div class="main-menu-search"
           role="search">
        <transition name="main-menu-search">
          <section v-show="searchShow"
                   class="main-menu-search__wrapper">
            <div class="main-menu-search-container">
              <label for="search-field"
                     class="main-menu-search__label"
              >
                {{ searchBarPlaceholder }}
              </label>
              <input id="search-field"
                     aria-describedby="search-field-help"
                     @keyup.enter="searchSubmit()"
                     @keyup="typeDetect"
                     autocomplete="off"
                     class="main-menu-search__input"
                     ref="searchInput"
                     type="search"
                     :maxlength="searchValueMaxLength"
                     v-model="searchValue">
              <transition name="main-menu-fade">
                <button
                  v-show="searchValueLenght > 0"
                  @click="searchClear"
                  class="main-menu-search__clear"
                  :aria-label="searchBarA11yAriaLabelClear">
                  {{ searchBarClearLabel }}
                </button>
              </transition>
            </div>
            <img loading="lazy"   @click="closeAll()"
                 alt="main menu search close"
                 class="main-menu-search__close-icon"
                 role="img"
                 :src="searchBarIconClear">
            <img loading="lazy" @click="searchSubmit()"
                 class="main-menu-search__submit"
                 :class="{ 'is-disabled': !searchAllowSubmit }"
                 alt="main menu search submit"
                 :src="searchBarIconSearch"
                 role="img">
            <div class="loader-css" v-show="showLoader">Loading</div>
            <transition name="main-menu-fade">
              <div class="main-menu-search__suggestion" :style="`--labelWidth : ${searchLabelWidth}px`" v-if="searchAllowSubmit">
                <transition-group name="main-menu-fade" tag="ul" >
                  <li v-for="(searchitemvalue, index) in searchKeyWords" :key="index+0">
                      <span  @click="searchTo(searchitemvalue)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M8.17241 15.3448C12.1336 15.3448 15.3448 12.1336 15.3448 8.17241C15.3448 4.2112 12.1336 1 8.17241 1C4.2112 1 1 4.2112 1 8.17241C1 12.1336 4.2112 15.3448 8.17241 15.3448Z" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                        <path d="M13.2373 13.248L17.0001 16.9998" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                        </svg>
                        {{ searchitemvalue }}
                        <!--<svg id="arrow-search" stroke-width="6" viewBox="0 0 74 74" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g id="eArrows_Search"><path id="eLine" class="est0" d="M6.3 6.2l55.6 61.7" /><path id="eLine-Copy" class="est0" d="M6.3 6.2h49.4" /><path id="eLine_1_" class="est0" d="M6.3 6.2v49.3" /></g></svg>-->
                      </span>
                  </li>
                </transition-group>
                <p id="search-field-help"
                   v-if="!searchAllowSubmit"
                   class="main-menu-search__help">
                  {{ searchBarHelpText }}
                </p>
              </div>
            </transition>

          </section>
        </transition>
      </div>
      <!-- Nav -->
      <nav role="navigation"
           class="main-menu-nav"
           :class="{ 'is-active': menuShow }"
           :aria-label="a11yAriaLabelPrincipalMenu">
        <transition name="main-menu-nav">
          <div class="main-menu-nav__wrapper"
               v-show="menuShow" @mouseleave="closeAll()">
            <div class="main-menu-nav__wrapper-background"
                 @click="closeAll()" />
            <slot name="nav"
                  :level1PrimaryItems="level1PrimaryItems"
                  :level1SecondaryItems="level1SecondaryItems"
                  :menuLevel2="menuLevel2"
                  :menuLevel3="menuLevel3"
                  :menuLevelOpen="menuLevelOpen"
                  :menuOnBlurSecondaryTab="menuOnBlurSecondaryTab" />
            <!-- account nav -->
            <div class="mobile-account" v-if="menuLevel2 < 1">
              <slot name="accountNav" :menuOnBlurSecondaryTab="menuOnBlurSecondaryTab" />
              <ul class="main-menu-nav__level-1 is-secondary nav-accounts" v-if="smallScreen" ref="mobileAccount">
                <li class="main-menu-nav__item" v-if="accEnable">
                  <a @click="openAccessibility()" class="accessibility-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                      <path d="M15 9C15 12.312 12.312 15 9 15V3C12.312 3 15 5.688 15 9Z" stroke="black" stroke-width="1.2" stroke-miterlimit="10"/>
                    </svg>
                    <span class="accessibility-menu">{{accTitle}}</span>
                  </a>
                </li>
                <li class="main-menu-nav__item" v-if="langSwitcherIsEnable">
                  <language-switcher :current-language="currentLanguage" :item-data="languageSwitcherData"> </language-switcher>
                </li>
              </ul>
              <ul class="main-menu-nav__level-1 is-secondary derm-scan">
                <slot name="derm-scan"></slot>
              </ul>
            </div>
          </div>
        </transition>
      </nav>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler, tag } from '@Foundation/analyticsHandler';
import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
import axios from 'axios';
import LanguageSwitcher from '@Feature/LanguageSwitcher/code/Scripts/components/language-switcher.vue';
import AccessibilityModalWindow from '@Feature/AccessibilityModalWindow/code/Scripts/components/accessibility-modal-window.vue';

import {
  customVhProperty,
  elementOrAncestorHasClass
} from '@Foundation/utilities';

export default {
  name: 'main-menu',
  components: { LanguageSwitcher, AccessibilityModalWindow },
  directives: {
    clickoutside,
    tag,
  },

  props: {
    level1PrimaryItems: {
      type: Array,
      default: undefined
    },
    level1SecondaryItems: {
      type: Array,
      default: undefined
    },
    a11yAriaLabelLanguage: {
      type: String,
      required: true,
      default: 'English (change language)'
    },
    a11yAriaLabelMainMenu: {
      type: String,
      required: true,
      default: 'Main Menu principal'
    },
    a11yAriaLabelPrincipalMenu: {
      type: String,
      required: true,
      default: 'Menu principal'
    },
    currentLanguage: {
      type: String,
      required: true,
      default: 'EN'
    },
    searchBarA11yAriaLabelClear: {
      type: String,
      required: false,
      default: 'Clear search field'
    },
    searchBarClearLabel: {
      type: String,
      required: false,
      default: 'Clear'
    },
    searchBarHelpText: {
      type: String,
      required: false,
      default: 'Please enter at least 3 characters'
    },
    searchBarIconClear: {
      type: String,
      required: false,
      default: '/assets/cross-icon.svg'
    },
    searchBarIconSearch: {
      type: String,
      required: false,
      default: '/assets/magnifier-icon.svg'
    },
    searchBarPlaceholder: {
      type: String,
      required: false,
      default: 'Ask for ingredients, concerns, questions...'
    },
    searchBarRedirectUrl: {
      type: String,
      required: false,
      default: '#'
    },
    searchBarApiUrl: {
      type: String,
      required: false,
      default: '#'
    },
    langSwitcherIsEnable: {
      type: Boolean,
      required: false,
      default: false
    },
    accTitle: {
      type: String, required: false, default: ''
    },
    accBlackIconSrc: {
      type: String, required: false, default: ''
    },
    accWhiteIconSrc: {
      type: String, required: false, default: ''
    },
    accIconAlt: {
      type: String, required: false, default: ''
    },
    accAnimationLabel: {
      type: String, required: false, default: ''
    },
    accContrastLabel: {
      type: String, required: false, default: ''
    },
    accEnableAnimation: {
      type: Boolean, required: false, default: ''
    },
    accEnableContrast: {
      type: Boolean, required: false, default: ''
    },
    accEnableToggleText: {
      type: String, required: false, default: ''
    },
    accDisableToggleText: {
      type: String, required: false, default: ''
    },
    pageCategory: {
      type: String, required: false, default: ''
    },
    accEventLabel: {
      type: String, required: false, default: ''
    },
    accEnable: {
      type: Boolean, required: false, default: ''
    },
  },

  data() {
    return {
      languagesShow: false,
      searchLabelWidth: 0,
      menuLevel2: 0,
      menuLevel2ClickedItem: undefined,
      menuLevel3: undefined,
      menuShow: false,
      overlayShow: false,
      searchShow: false,
      searchValue: '',
      searchValueMaxLength: 200,
      displayLanguageSwitcher: !!this.$slots.mainMenuBarLanguages,
      showLoader: false,
      searchKeyWords: '',
      errors: '',
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      showHeader: true,
      lastScrollPosition: 0,
      scrollOffset: 0,
      isSticky: false,
      isTransparentHeader: false,
      spotScan: null,
      spotScanOnce: false,
      scrollTimeout: false,
      languageSwitcherData: [],
      isNewsletterStickyOpen: false,
    };
  },

  computed: {
    /**
     * If the search value is allowed to be submitted
     * @returns {boolean}
     */
    searchAllowSubmit() {
      if (this.searchValue.length >= 3 && this.searchValueLenght < this.searchValueMaxLength) {
        this.showLoader = true;
        this.search();
        return true;
      }

      return false;
    },

    /**
     * User search value length
     * @returns {number}
     */
    searchValueLenght() {
      return this.searchValue.length;
    },

    /**
     * Appends the search value to propped redirect url
     * @returns {string}
     */
    searchUrl() {
      return this.searchBarRedirectUrl.concat(encodeURIComponent(this.searchValue));
    },
  },

  watch: {
    menuLevel3() {
      this.$nextTick(() => {
        this.menuCalculatedLayoutCorrections();
      });
    }
  },
  created() {
    window.eventBus.$on('newsletter-sticky-open-menu', (val) => {
      let content = document.querySelector('#content') ? document.querySelector('#content') : '';
      this.isNewsletterStickyOpen = val;
      if (content) {
        val ? content.setAttribute('style', 'margin-top: 57px') : content.removeAttribute('style');
      }
    });
  },
  mounted() {
    customVhProperty();
    this.scrollEventAdd();
    this.languagesAddBlurEvent();
    var imgTransparent = document.getElementsByClassName('homepage-transparncy')[0];
        if (!imgTransparent) {
            document.querySelector('body').classList.remove('home-page-transparancy-slider');
        }
    eventBus.$on('mediaquery::changed', mq => {
      this.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
      if (this.smallScreen) {
        this.menuShow = false;
      } else {
        this.menuShow = true;
      }
    });
    this.lastScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if(document.querySelector('#doctor-online1')){
      const main = document.querySelector('#doctor-online1');
      if(main) {
        main.addEventListener('click', (e) => {
          e.preventDefault();
          this.onlinedoctor('slot1');

        })
      }
    }
    if(document.querySelector('#doctor-online2')){
      const main2 = document.querySelector('#doctor-online2');
      if(main2) {
        main2.addEventListener('click', (e) => {
          e.preventDefault();
          this.onlinedoctor('slot2');
        })
      }
    }
    window.addEventListener('scroll', this.scrollEventHandle);
    window.eventBus.$on('transparent-header', this.handleTransparent)
    this.isTransparentHeader ? document.querySelector('body').classList.add('transparent-header') : document.querySelector('body').classList.add('static-header')
    if (this.langSwitcherIsEnable) {
      const langData = window && window.languageSwitcherData ? window.languageSwitcherData : '[]'
      this.languageSwitcherData = JSON.parse(langData.replace(/&quot;/g, '"'));
    }
  },
  methods: {
    handleTransparent(payload) {
      this.isTransparentHeader = payload;
      this.isTransparentHeader ? document.querySelector('body').classList.add('transparent-header') : document.querySelector('body').classList.add('static-header')
    },
    onlinedoctor(slot) {
      window.dataLayer.push({
        event: "promotionClick",
        eventAction: "Ecommerce",
        eventCategory: "Promotion Click",
        eventLabel: "OnlineDoctor::OnlineDoctor",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: "OnlineDoctor",
                name: "OnlineDoctor",
                creative: "Weiterleitung OnlineDoctor",
                position: slot,
              }
            ]
          }
        }
      });

    },
    openAccessibility() {
      document.getElementById('accessibility-popup').classList.add('show');
      window.eventBus.$emit('accessibility-click', 'header');
      const deviceTagging = window.innerWidth >= 1024 ? 'header' : 'burger menu';
      window.dataLayer.push({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'event_name': 'menu_click',
        'eventCategory': 'main menu navigation',
        'eventAction': 'select::' + deviceTagging,
        'eventLabel': 'accessibility',
        'click_area': deviceTagging,
        'breadcrumb': 'accessibility'
      });
    },
    search() {
      const url = this.searchBarApiUrl + this.searchValue;
      // let fakeApi = 'https://api.mocki.io/v1/8de961e3'
      axios.get(url)
      .then((response) => this.searchKeyWords = response.data, this.showLoader = false);
    },

    searchTo(searchKey) {
      window.location = this.searchBarRedirectUrl + searchKey + '&autosug=auto';
    },

    /**
     * Closes both search and navigation
     * @returns {undefined}
     */
    closeAll() {
      this.overlayToggle(false);
      this.languagesClose();
      this.searchShow = false;
      this.deSelectMenu();
      let languageswitecher = document.getElementsByClassName('main-menu-bar__languages-new');
      if (languageswitecher.length > 0) {
        document.querySelector('.language-switcher').classList.remove('hide-language__switcher');
      }
    },

    deSelectMenu() {
      this.menuLevel2 = 0;
      this.menuLevel3 = 0;
      if (!this.smallScreen) {
        this.menuShow = true;
      } else {
        this.menuShow = false;
      }
    },

    /**
     * Returns a boolean checking if the user is using his keyboard instead of a mouse or touch
     * @returns {boolean}
     */
    isUsingKeyboard() {
      return document.getElementsByTagName('body')[0].classList.contains('is-using-keyboard') ? true : false;
    },

    /**
     * Setup event listener for blur event (required for slotted html)
     * A11Y requirement
     * @returns {undefined}
     */
    languagesAddBlurEvent() {
      const slotLanguages = document.getElementsByClassName('js-main-menu-language');
      for (let i = 0; i < slotLanguages.length; i++) {
        slotLanguages[i].addEventListener('blur', this.languageOnBlur);
      }
    },

    /**
     * Closes languages
     * @returns {undefined}
     */
    languagesClose() {
      this.languagesShow = false;
    },

    /**
     * Checks if any languages are still focused, if not closes the submenu
     * A11Y requirement
     * @returns {undefined}
     */
    languageOnBlur() {
      const slotLanguages = document.getElementsByClassName('js-main-menu-language');
      let focus = false;
      setTimeout(() => {
        if (document.activeElement === this.$refs.languageBtn) {
          focus = true;
        }

        if (!focus) {
          for (let i = 0; i < slotLanguages.length; i++) {
            if (document.activeElement === slotLanguages[i] && document.hasFocus()) {
              focus = true;
            }
          }
        }

        if (!focus) {
          this.languagesShow = false;
        }
      }, 10);
    },

    /**
     * Toggles the languages
     * @returns {undefined}
     */
    languagesToggle() {
      this.languagesShow = !this.languagesShow;
    },

    /**
     * Open or close the overlay
     * Apply a class to the body to enable scrolling
     * @param {boolean} show - True to open, false to close
     * @returns {undefined}
     */
    overlayToggle(show) {
      const body = document.querySelector('body');

      this.overlayShow = show;

      if (show) {
        body.classList.add('body-hide-overflow');
      } else {
        body.classList.remove('body-hide-overflow');
      }
    },

    /**
     * Minor layout corrections not achievable with pure CSS
     * (Due to A11Y structural requirements)
     * @returns {undefined}
     */
    menuCalculatedLayoutCorrections() {
      const leve2wrapper = document.getElementsByClassName('main-menu-nav__level-2-wrapper');
      if (leve2wrapper.length > 1 && this.menuLevel3 && window.innerWidth < 1024) {
        const minHeight = leve2wrapper[0].scrollHeight;

        // Jump to the top, to avoid beginning half way the menu (if scrolled on level 2)
        leve2wrapper[0].scrollTop = 0;
        // Set a min height for level 3 based on that of level 2
        // Otherwise level 2 might show underneath level 3
        document.querySelector('.main-menu-nav__level-3.active-animation').style.minHeight = `${minHeight}px`;
      }

      if (leve2wrapper.length > 1 && this.menuLevel3 && window.innerWidth >= 1024) {
        const minHeight = document.getElementsByClassName('main-menu-nav__level-3')[0].scrollHeight;

        if (minHeight >= 400) {
          leve2wrapper[0].style.minHeight = '400px';
        } else {
          // Set a min height for level 2 based on that of level 3
          // So no overflow scrolling is required
          leve2wrapper[0].style.minHeight = `${minHeight}px`;
        }
      }
      if (leve2wrapper.length > 1 && !this.menuLevel3) {
        // Reset calculated min height
        leve2wrapper[0].style.minHeight = '';
      }
    },

    /**
     * On blur secondary tab close the menu if no longer focussing items in the menu
     * This functionality was written based on criteria the last focusable items in the memu
     * are always level 1 secondary tabs
     * @returns {undefined}
     */
    menuOnBlurSecondaryTab() {
      setTimeout(() => {
        if (!elementOrAncestorHasClass(document.activeElement, 'main-menu')) {
          this.closeAll();
        }
      }, 10);
    },

    /**
     * Toggles the mega menu
     * @returns {undefined}
     */
    menuToggle() {
      this.menuShow = !this.menuShow;
      if (this.menuShow) {
        this.searchShow = false;
        this.overlayToggle(true);
      } else {
        this.overlayToggle(false);
      }
    },

    /**
     * Sets level 2 and 3 indexes to show in view
     * @param {boolean} level2 - Index of level 2 item
     * @param {boolean} level3 - Index of level 3 item (optional)
     * @returns {undefined}
     */
    menuLevelOpen(level2, level3, event) {
      this.menuLevel2 = level2;
      this.menuLevel3 = level3;
      this.overlayToggle(true);
      // A11Y requirements
      // When requesting level 3 store the level 2 node we clicked
      if (level3 && event) {
        this.menuLevel2ClickedItem = event.target;
      }

      // Auto focus on requesting level 3 menu
      if (level3 && this.isUsingKeyboard()) {
        this.$nextTick(() => {
          if (window.innerWidth < 1024) {
            document.getElementsByClassName('main-menu-nav__level-3-mobile-back')[0].focus();
          }
        });
      }

      // Auto focus the stored level 2 item on using the mobile back button level 3
      if (level2 && !level3 && this.isUsingKeyboard() && window.innerWidth < 1024) {
        this.$nextTick(() => {
          if (this.menuLevel2ClickedItem) {
            this.menuLevel2ClickedItem.focus();
          }
        });
      }

      //add css class to make a columns
      const level3Links = this.$el.querySelectorAll('.main-menu-nav__level-3 ul');
      [...level3Links].forEach(item => {
        item.querySelectorAll('li').length > 7 && item.classList.add('with-cols');
      });
    },

    /**
     * Handles showing or hiding the bar when scrolling
     * @param {number} scrollPos - Scroll position on time of request
     * @returns {number} Current scroll position
     */
    scrollEventHandle() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      if (!this.searchShow) {
        // Check if the scroll position is greater than 48
        if (scrollPosition > 48) {
          // Use a flag to control the update
          if (!this.scrollTimeout) {
            this.scrollTimeout = true;
            // Set a timeout to delay the update
            setTimeout(() => {
              this.showHeader = scrollPosition > this.lastScrollPosition;
              this.scrollTimeout = false;
            }, 100); // Adjust the delay time as needed (in milliseconds)
          }
        } else if (scrollPosition < 52) {
          this.showHeader = true;
        }
      }
      this.showHeader ? window.eventBus.$emit('stickyHeader', true) : window.eventBus.$emit('stickyHeader', false);
      this.lastScrollPosition = scrollPosition;
      // Set the isSticky variable based on the scroll position
      this.isSticky = scrollPosition > 48 && !this.searchShow;
      let headerWrapper = document.querySelector('.header__wrapper');
      if (headerWrapper) {
        setTimeout(() => {
        if (this.showHeader) {
          headerWrapper.classList.add('sticky-header');
        } else {
          headerWrapper.classList.remove('sticky-header');
        }
        },100);
      }
    },

    /**
     * Setup event listener for scrolling (required for sticky bar)
     * @returns {undefined}
     */
    scrollEventAdd() {
      if (this.smallScreen) {
        this.menuShow = false;
      } else {
        this.menuShow = true;
      }
    },

    /**
     * Clear the user search value
     * @returns {undefined}
     */
    searchClear() {
      this.searchValue = '';
      this.searchFocus();

    },

    /**
     * Focusses the search bar input field
     * @returns {undefined}
     */
    searchFocus() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },

    /**
     * Redirects to Sitecore page for handeling the search request
     * @returns {undefined}
     */
    searchSubmit() {
      if (this.searchAllowSubmit) {
        this.showLoader = true;
        setTimeout(() => {
          window.location.assign(this.searchUrl);
        }, 300);
      }
    },

    /**
     * Toggles the sarch bar
     * @returns {undefined}
     */
    searchToggle() {

      this.searchShow = !this.searchShow;
      this.$nextTick(() => {
        this.searchLabelWidth = document.querySelector('.main-menu-search__label').clientWidth + 13;
      })
      if (!this.smallScreen) {
        this.menuShow = !this.menuShow;
      } else {
        this.menuShow = false;
      }

      if (this.searchShow) {
        this.overlayToggle(true);
        this.searchFocus();
        const deviceTagging = window.innerWidth >= 1024 ? 'header' : 'burger menu';
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'menu_click',
          'ecommerce': undefined,
          'eventCategory': 'main menu navigation',
          'eventAction': 'select::' + deviceTagging,
          'eventLabel': 'search',
          'click_area': deviceTagging,
          'breadcrumb': 'search'
        });
      } else {
        this.overlayToggle(false);
      }
    },

    /**
     * crutch for android device keyborf tap not bieng detected
     */
    typeDetect() {
      if (this.$refs.searchInput.value !== this.searchValue) {
        this.searchValue = this.$refs.searchInput.value;
      }
    },
    showCart() {
      document.body.classList.add('showPopupCart');
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollEventHandle)
  },
};

</script>

<style lang="scss" src="./_main-menu.scss"></style>
<style lang="scss" src="./_main-menu-overlay.scss"></style>
<style lang="scss" src="./_main-menu-bar.scss"></style>
<style lang="scss" src="./_main-menu-search.scss"></style>
<style lang="scss" src="./_main-menu-nav.scss"></style>
<style lang="scss" src="./_main-menu-cart.scss"></style>
