import Vue from 'vue';
export const routineStore = {
    state: {
        questionCount: 0,
        currentIndex: 0,
        questionList: [],
        answerDataList: [],
        answerIds: [],
        buttonSetting: [],
        spotscanData: [],
        finalQuestion: [],
        isHomePage:true,
    },
    mutations: {
        setQuestionAnswer(state, value) {

            state.questionList = value;
        },
        setQuestionCount(state, value) {
            state.questionCount = value;
        },
        setAnswerDataList(state, value) {

            state.answerDataList.push(value);
        },
        setanswerIds(state, value) {

            state.answerIds.push(value);
        },
        setCurrentIndex(state, value) {
            state.currentIndex += value === 'next' ? 1 : -1;
        },
        setAnswerIdsAtIndex(state, value) {
            let answerDatas = state.answerIds;
            const existingAnswer = answerDatas[value[0]];
            if (existingAnswer) {
                existingAnswer[1] = value[1];
                existingAnswer[2] = value[2];
                existingAnswer[3] = value[3];

            } else {
                state.answerIds.push(value)
            }
        },
        setbuttonSetting(state, value) {
            state.buttonSetting = value;
        },
        removeAnswerAtIndex(state, index) {
            if (Array.isArray(state.answerDataList)) {
                state.answerDataList = state.answerDataList.filter(answer => answer.index <= index);
            }
        },
        setSpotScanData(state, value) {
            state.spotscanData = value;
        },
        setFinalQuestion(state, value) {
            state.finalQuestion = value;
        },
        resetQuestionAnsDatas(state) {
            state.currentIndex = 0;
            state.answerDataList = [];
            state.answerIds = [];
           // state.spotscanData = [];
            //state.finalQuestion = [];
        },
        setIsHomePage(state,value){
            state.isHomePage = value;
        },
        resetHomepageStatus(state){
            state.isHomePage=true;
        }
    },
    actions: {
        async apicall({ commit }) {
            commit('resetQuestionAnsDatas');
            commit('resetHomepageStatus');
            const apiURL = '/api/routinefinder/RoutineFinderApiBus';
            const jsonData = {
                "Stage": 2
            };
            try {
                const response = await fetch(apiURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify(jsonData),
                });
                if (response.ok) {
                    this.questionAnsForm = true;
                    const data = await response.json(); // Convert response to JSON
                    //this.questionList = data.data.question_answers;
                    commit('setQuestionAnswer', data.data.question_answers);
                    commit('setQuestionCount', data.data.count);
                    commit('setbuttonSetting', data.data.buttons);
                    commit('setSpotScanData', data.data.spotscan_data);
                    commit('setFinalQuestion', data.data.final_question);

                } else {
                    console.error('Error:', response.status, response.statusText);
                }
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

    },
    getters: {
        getQuestionCount: (state) => state.questionCount,
        getCurrentIndex: (state) => state.currentIndex,
        getQuestionList: (state) => state.questionList,
        getAnswerDataList: (state) => state.answerDataList,
        getAnswerIds: (state) => state.answerIds,
        getButtonSetting: (state) => state.buttonSetting,
        getSpotScanData: (state) => state.spotscanData,
        getFinalQuestion: (state) => state.finalQuestion,
        getIsHomePage:(state) => state.isHomePage,
    }
}

