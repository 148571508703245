<template>
    <div class="routine-finder-your-profile">
        <div class="container--is-maxwidth container--padded faq">
            <div class="faq_accordions__wrapper">
                <div class="accordion__list --section-one">
                    <button class="accordion" :class="{ 'is-open': isOpen }">{{ getButtonSetting.your_profile }}</button>
                    <span class="faq-accordion-btn" @click="toggle()" :class="{ 'is-open': isOpen }">
                        <span class="--active"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24"
                                viewBox="0 0 25 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.9 11.4001L1.18629 11.4001L1.18629 12.6001L11.9 12.6001L11.9 23.3138H13.1L13.1 12.6001L23.8137 12.6001L23.8137 11.4001L13.1 11.4001L13.1 0.686426H11.9L11.9 11.4001Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="--inactive">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
                                fill="none">
                                <path d="M23.8137 12.0002H1.18629" stroke="black" stroke-width="1.2"
                                    stroke-miterlimit="10" />
                            </svg>
                        </span>
                    </span>
                    <div class="accordion-content">
                        <div v-for="(item, index) in getAnswerDataList" :key="index">
                            <p> {{ item.question }} : <span> {{ item.answerValue }}</span></p>
                        </div>
                    </div>
                </div>
                <!-- Dynamic Values-->

                <div class="accordion__list --section-two" v-for="(items, index) in getAnswerDataList[0].accordion" :key="index" :class="{ 'is-open': isOpen2[index] }">
                    <button class="accordion" :class="{ 'is-open': isOpen2[index] }">{{ items.title }}</button>
                    <span class="faq-accordion-btn" @click="toogleSecion2(index)" :class="{ 'is-open': isOpen2[index] }">
                        <span class="--active"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24"
                                viewBox="0 0 25 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.9 11.4001L1.18629 11.4001L1.18629 12.6001L11.9 12.6001L11.9 23.3138H13.1L13.1 12.6001L23.8137 12.6001L23.8137 11.4001L13.1 11.4001L13.1 0.686426H11.9L11.9 11.4001Z"
                                    fill="black" />
                            </svg>
                        </span>
                        <span class="--inactive">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
                                fill="none">
                                <path d="M23.8137 12.0002H1.18629" stroke="black" stroke-width="1.2"
                                    stroke-miterlimit="10" />
                            </svg>
                        </span>
                    </span>
                    <div class="accordion-content">
                        <p> {{ items.description }}</p>
                    </div>
                </div>
            </div>
            <!------>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
export default {
    name: 'routine-finder-your-profile',
    directives: {
    truncateRichText
  },
    props: {
        options: {
            type: Object,
            default() {
                return undefined;
            }
        },

    },
    data() {
        return {
            isOpen: true,
            isOpen2: [],
        }
    },
    computed: {
        ...mapGetters(['getAnswerDataList','getButtonSetting']),
    },     
    created() {
        if (this.getAnswerDataList && this.getAnswerDataList[0]?.accordion) {
            this.isOpen2 = Array(this.getAnswerDataList[0].accordion.length).fill(false);
        }
    },
    watch: {
        'getAnswerDataList[0].accordion': {
            handler(newAccordion) {
                if (newAccordion) {
                    this.isOpen2 = Array(newAccordion.length).fill(false);
                }
            },
            immediate: true,
        },
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        toogleSecion2(index) {
            this.$set(this.isOpen2, index, !this.isOpen2[index]);
        }
    }
}
</script>
<style lang="scss" src="./routine-finder-your-profile.scss"></style>