<template>
  <div class="lrp-retailer" :class="[ productDesbutton ? '':' cell']" >
    <div class="buyOnline -btnOnly" v-if="!stickyBuynowButton">
      <div @click="buybuttonselect()" class="popupContainer__bagIcon popupContainer__bagIcon_buy" >
        <img alt="bag" src="/frontend/master/images/icons/bag--black.svg">
      </div>
      <button :class="!ctaClass ? ctaClass : 'cta cta--light actionBtn buyOnline'" @click="buybuttonselect()">
            <span class=" btn__wrapper ">
              {{ dataTextLable }}
            </span>
      </button>
    </div>
    <div v-else-if="(stickyBuynowButton && !smallScreen) || (stickyBuynowButton && oneVariant)">
      <p class="actionBtn buyOnline" @click="buybuttonselect()"> {{ dataTextLable }} </p>
    </div>
    <div v-if="stickyBuynowButton && smallScreen && oneVariant === false ">
      <p class="actionBtn buyOnline" @click="mobilebuybutton()"> {{ dataTextLable }} </p>
    </div>

    <div class="popupContainerOuter" id="popupContainerOuter" v-show="showPopup">
      <div class="popupContainer">
        <div class="popupContainer__close" @click="closePopupScreen(), showPopup = !showPopup;">&#10006;</div>
        <div class="container" id="app">

          <div class="popupContainer__inner" id="commerceConnectWrapper">
            <div class="container--is-maxwidth container--padded-hor" v-if="retailerdata.length > 0">
              <slider :per-page-custom="[[320, 1], [1024, 4]]" :is-pagination-clickable="false" :loop="false" :with-focusable-items="true" :scroll-per-page="true" :start-at="0" :navigation-enabled="true" :pagination-enabled="true" :swipe-on-desktop="false"  :nav-tagging-label="navTaggingLabel" :item-per-page-desktop="4" :page-category="pageCategory" ref="slider">
                <slide v-for="(retailer, index) in retailerdata" :key="index">
                  <a :href="retailer.RetailerLink" @click="gatagging(retailer.AddToCartTags,retailer.WhereToBuyTags,retailer.ProductRetailerItemName, index)" target="_blank" class="card-shop" panel-focusable="">
                    <div class="card-shop__img-wrapper">
                      <img :src="retailer.RetailerImage" :alt="retailer.ProductRetailerItemName" class="card-shop__img" width="200" height="65">
                    </div>

                    <div class="btn btn--black btn--buy btn--icon">
                      <img :src="retailer.CTAIcon" class="btn__icon" aria-hidden="true" :alt="retailer.CTALabel">
                      <span class="btn__wrapper">
                                    <span class="btn__text">
                                    {{retailer.CTALabel}}
                                    </span>
                                </span>
                    </div>
                  </a>
                </slide>

              </slider>
            </div>
            <div v-else>
              <p class="no-product" style="text-align: center;">{{errorMessgage}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popupContainerOuter variantPopupOuter capacityPopupContainerOuterroutine" id="capacityPopupContainerOuter" v-show="capacityPopup">
      <div class="variant popupContainer">
        <div class="popupContainer__close" @click="closecapacityPopupScreen()">&#10006;</div>
        <div class="container pdp-stikcy-bar" id="app">
          <div class="sticky-parent">
            <div class="sticky__heading">
              <img :src="productImg" alt="product images" >
            </div>
            <div class="sticky__select">
              <div class="sticky__title" v-html="pfdTitle"/>
              <div class="sticky__select--select_capacity">
                <volume-variant-v2
                  :volumes="volumesDetail"
                  :legend-name="capacityText"
                  a11y-aria-label="legend-volume current-volume"
                  :sfccvolumesdetails="variantDetail"
                  :popup-dropdown="true"
                  :productid="productIds"/>
              </div>
              <div class="sticky__select--select_color" v-if="colorList.length > 0">
                <color-variant-v2 a11y-close-panel-text= "panelText"
                                  a11y-next-panel-text= "nextPanelText"
                                  a11y-previous-panel-text= "previousPanelText"
                                  :colors="colorList"
                                  :legend-name="colorText"
                                  itemprop="color"
                                  :productid="productIds"
                                  :sfcccolorsdetails="colorsDetails"
                                  :popup-dropdown="true"/>
              </div>
            </div>
          </div>
          <div class="sticky__buynow-button">
            <p class="actionBtn buyOnline" @click="buybuttonselect(true)"> {{ dataTextLable }} </p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { tag } from '@Foundation/analyticsHandler';
import Panel from '@Feature/Panel/code/Scripts/components/panel.vue';
import Slide from '@Feature/Slider/code/Scripts/components/slide/slide';
import Slider from '@Feature/Slider/code/Scripts/components/slider/slider';
import ColorVariantV2 from '@Feature/ColorVariant-V2/code/Scripts/components/color-variant-v2';
import VolumeVariantV2 from '@Feature/VolumeVariant-V2/code/Scripts/components/volume-variant-v2';

export default {
  name: 'lrp-retailer',
  components: { Panel,
    Slide,
    Slider,
    ColorVariantV2,
    VolumeVariantV2
  },
  directives: {
    tag
  },
  props: {
    dataBynowTagging: { type: Object, required: true },
    initialData:{type: Object, required: true },
    dataVariantEan:{ type: String, required: false },
    dataTextLable: { type: String, required: false, default: 'Buy Now' },
    a11yNextPanelText: { type: String, required: false, default: 'Next panel' },
    a11yPreviousPanelText: { type: String, required: false, default: 'Previous panel' },
    errorMessgage: { type: String, required: false, default: '' },
    stickyBuynowButton: { type: Boolean, required: false, default: false },
    pfdTitle: { type: String, required: false, default: '<strong>TOLERIANE</strong> <br/> SENSITIVE <br/>LE TEINT CREME' },
    productImg: { type: String, required: false, default: '' },
    activeColor: { type: String, required: false, default: '#009FE3' },
    variantDetail: { type: Array, required: false, default: [] },
    volumesDetail: { type: Array, required: false, default: [] },
    colorsDetails: { type: Array, required: false, default: [] },
    colorList: { type: Array, required: false, default: [] },
    productIds: { type: Array, required: false, default: [] },
    productName: { type: String, required: false, default: '' },
    tagEvent: { type: Boolean, required: false, default: false },
    routineProduct: { type: Boolean, required: false, default: false },
    capacityText: { type: String, required: false, default: 'Capacity' },
    colorText: { type: String, required: false, default: 'Color' },
    productId: { type: String, required: false, default: '' },
    productEan: { type: String, required: false, default: '' },
    productDesbutton: { type: Boolean, required: false, default: false },
    routineProductDektop: { type: Boolean, required: false, default: false },
    routineProductMobile: { type: Boolean, required: false, default: false },
    oneVariant:{ type: Boolean, required: false, default: false },
    ctaClass: {type: String},
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    pageCategory: { type: String, required: false, default: 'page category' },

  },
  data() {
    return {
      capacityPopup: false,
      eretailer: [],
      eretailerlist: [],
      retailerdata:'',
      shopsUnavailable: true,
      showPopup: false,
      show: 0,
      allRetailer:this.initialData.IsPrdAllRetailerEnabled,
      payloadValue :'',
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      eanvalue:'',

    };
  },
  mounted() {
    if(this.allRetailer === true){
      this.generatedRetailer(this.initialData.AllRetailersList);
      //this.retailerdata = this.initialData.AllRetailersList;
      this.retailerdata= this.eretailerlist;
    }
    if((this.routineProductDektop && !this.smallScreen) || (this.routineProductDektop && this.oneVariant)){
      this.buybuttonselect();
    }
    if(this.routineProductMobile){
      this.mobilebuybutton();
    }

    window.eventBus.$on('selectedVarientSize', payload => {
      this.eanvalue =payload;
      if(payload && this.allRetailer === false){
        this.payloadValue =payload;
        this.sendRequest(payload);
      }
    });

  },
  methods: {
    generatedRetailer(retailers){
      if (retailers != null) {
        for (let i = 0; i < retailers.length; i++) {
          this.valueexist(retailers[i]);
        }
      }
    },
    valueexist(retailer){
      if(this.eretailerlist.length >1){
        for(let i=0; i<=this.eretailerlist.length; i++ ){
          if(!this.eretailerlist.some(data => data.ProductRetailerItemName === retailer.ProductRetailerItemName)){

            this.eretailerlist.push(retailer);
          }
        }
      }else{
        this.eretailerlist.push(retailer);
      }


    },
    gatagging(carttagging,wheretobuytagging,retailername, index){
      if(carttagging && this.allRetailer === false){
        window.dataLayer.push({ ...carttagging });
      }
      if(this.allRetailer === true){
        var currentEan='';
        if(this.eanvalue==''){
          currentEan=this.dataVariantEan;
        }
        else{
          currentEan=this.eanvalue;
        }
        for(let i=0; i<this.initialData.AllRetailersList.length; i++){
          if(this.initialData.AllRetailersList[i].ProductRetailerItemName === retailername && this.initialData.AllRetailersList[i].VariantEANId === currentEan){
              window.dataLayer.push({ ...this.eretailerlist[index].AddToCartTags});        
          }
        }
      }
    },
    buybuttonselect: function(mobile=null) {
      if(mobile){
        this.capacityPopup =false;
      }
      if(this.allRetailer === false){
        if(this.payloadValue){
          this.sendRequest(this.payloadValue);
        }else{
          this.sendRequest(this.dataVariantEan);
        }
      }
      if (this.payloadValue === '') {
        this.payloadValue = this.initialData.DefaultEAN;
      }
      if (this.initialData.VariantRetailerList != null && this.initialData.VariantRetailerList.length > 0) {
        for (let i = 0; i < this.initialData.VariantRetailerList.length; i++) {
          if(this.initialData.VariantRetailerList[i].VariantID === this.payloadValue){
            window.dataLayer.push(this.initialData.VariantRetailerList[i].WhereToBuyTags);
          }
        }
      }
      document.getElementById('popupContainerOuter').classList.add('showPopup');
      document.querySelector('.sticky-wrapper')?.classList.add('sticky-wrapper__active');
      document.querySelector('.buyOnline').classList.add('buyOnline__active');
      if(this.showPopup){
        this.showPopup=false
      }else{
        this.showPopup = true;
      }
    },
    closePopupScreen() {
      document.getElementById('popupContainerOuter').classList.remove('showPopup');
      document.querySelector('.sticky-wrapper')?.classList.remove('sticky-wrapper__active');
      document.querySelector('.buyOnline').classList.remove('buyOnline__active');
      window.eventBus.$emit('lrpretailer', false);
      document.body.style.removeProperty('overflow');
    },
    sendRequest(selectedEan){
      this.eretailer =[];
      this.retailerdata='';
      for (let i = 0; i < this.initialData.VariantRetailerList.length; i++) {

        if(this.initialData.VariantRetailerList[i].VariantID==selectedEan){
          if(this.initialData.VariantRetailerList[i].RetailerList.length > 0){
            this.retailerdata = this.initialData.VariantRetailerList[i].RetailerList;

          }
        }
      }

    },
    isUsingKeyboard() {
      return document.body.classList.contains('is-using-keyboard');
    },

    a11ySlider() {
      const slidesOnPage = this.$refs.slider.currentPerPage;
      const currentSlide = this.$refs.slider.currentPage;
      /* istanbul ignore else */
      if (this.isUsingKeyboard()) {
        this.activeShade = currentSlide * slidesOnPage;
        this.$nextTick();
      }
    },
    productBuynowTag() {
      window.dataLayer.push({
        'event': 'uaevent',
        'event_name': 'buy_now',
        'ecommerce': 'undefined',
        'eventCategory': 	'Ecommerce',
        'eventAction': 'click to buy',
        'eventLabel': `${[this.productName]}::${[this.productEan]}`,
        'product_info': `${[this.productName]}::${[this.productId]}`,
      });
    },
    mobilebuybutton: function() {
      this.productBuynowTag();
      this.capacityPopup = true;
      document.getElementById('capacityPopupContainerOuter').classList.add('showPopup');
      document.querySelector('.buyOnline').classList.add('buyOnline__active');
      document.body.style.overflow = 'hidden';
      document.getElementById('main').classList.add('variantPopup_active');
    },
    closecapacityPopupScreen: function() {
      this.capacityPopup = false;
      this.showPopup =false;
      window.eventBus.$emit('lrpretailer', false);
      document.getElementById('capacityPopupContainerOuter').classList.remove('showPopup');
      document.querySelector('.buyOnline').classList.remove('buyOnline__active');
      document.body.style.removeProperty('overflow');
      document.getElementById('main').classList.remove('variantPopup_active');
    },


  }
}

</script>
<style lang="scss" src="./lrp-retailer.scss"></style>
