<template>
  <nav class="routine_finder__step">
    <span class="step_line"></span>
    <ul class="routine_finder__step__list">
      <li class="routine_finder__step__list__item" v-for=" n in getQuestionCount" :key="n"><button
          :class="['stepBtn', getCurrentIndex === n - 1 ? '--active' : getCurrentIndex < n - 1 ? '' : '--previous']"><span
            class="qa_number">{{ n }}<span class="tick"></span>
          </span><span class="qa_title" v-truncate-rich-text="{ limit: 25, ellipse: false, singleLine: true }">
            {{ getQuestionList[n - 1].category }}</span></button></li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
export default {
  name: 'routine-finder-nav-step',
  directives: {
    truncateRichText
  },
  computed: {
    ...mapGetters(['getQuestionCount', 'getQuestionList', 'getAnswerDataList', 'getCurrentIndex']),
  },

};
</script>
<style lang="scss" src="./routine-finder-navi-step.scss"></style>